@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind Framework base layer extensions */
@layer base {
  h1 {
    @apply mt-3 mb-2 text-3xl font-semibold tracking-tight dark:text-white;
  }
  h2 {
    @apply mb-2 mt-3 text-2xl font-semibold tracking-tight dark:text-white;
  }
  h3 {
    @apply mb-2 mt-3 text-xl font-semibold tracking-tight dark:text-white;
  }
  p {
    @apply mb-4 text-base;
  }
  ol {
    @apply mb-4 text-base;
  }
  ul {
    @apply mb-4 text-base;
  }

  /* Dark mode */
  body {
    @apply dark:bg-gray-900;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul {
    @apply dark:text-white;
  }

  h1 input {
    @apply dark:bg-gray-800 dark:text-white;
  }

  input,
  textarea,
  select,
  button {
    @apply dark:bg-gray-800 dark:text-white;
  }

  a {
    @apply text-indigo-500;
  }

  .link {
    @apply text-indigo-500 hover:text-indigo-400;
  }
}

@layer components {
  .nav-link {
    @apply ml-6;
  }
  .project-metadata {
    @apply mb-0 text-sm text-gray-600;
  }
  .thumbnail-sm {
    @apply mb-0 h-7 w-7 shadow;
  }

  .page-heading {
    @apply text-3xl font-semibold text-gray-900 dark:text-white sm:text-4xl;
  }

  .gradient-text {
    @apply bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent underline;
  }

  .user-badge {
    @apply mb-2 mr-2 flex flex-shrink;
  }

  /* Text */
  .large-heading {
    @apply mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl;
  }

  /* Rich Text */
  .rich-text-editor .ProseMirror-focused {
    @apply outline-none;
  }

  .rich-editor-bubble-menu {
    @apply relative left-4 z-20 m-0 flex w-[244px] flex-wrap items-center space-y-1 rounded-3xl border-[0.5px] border-gray-200 bg-white p-1 px-1 shadow-lg sm:left-0 sm:w-[374px] sm:space-x-1 sm:space-y-0 sm:px-2;
  }

  .rich-editor-floating-menu {
    @apply relative bottom-2 z-20 flex items-center space-x-1 rounded-lg;
  }

  .rich-editor-bubble-menu button {
    @apply p-2;
  }

  .rich-editor-bubble-menu .is-active {
    @apply rounded-full bg-gray-100;
  }

  .rich-editor-bubble-menu button:hover {
    @apply rounded-full bg-gray-100 shadow-none;
  }

  .rich-editor-floating-menu button {
    @apply mt-4 rounded-full border-[0.5px] border-gray-200 p-2 shadow-lg;
  }

  .rich-editor-floating-menu button:hover {
    @apply mt-4 rounded-full border-[0.5px] border-gray-200 bg-gray-100 p-2;
  }

  .rich-editor-floating-menu label {
    @apply mt-4 cursor-pointer p-2;
  }

  .rich-text-editor {
    @apply mb-10;
  }

  .rich-text-editor code {
    @apply rounded-lg bg-gray-300 py-1 px-1 text-sm;
  }

  .rich-text-editor img {
    @apply mx-auto mb-8 mt-7 h-auto rounded-lg border border-gray-100 shadow-lg lg:w-5/6;
  }

  .rich-text-editor [data-youtube-video] {
    @apply mx-auto mb-8 h-auto rounded-lg border border-gray-100 shadow-lg;
  }

  .rich-text-editor hr {
    @apply my-6;
  }
  .rich-text-editor ol li {
    @apply ml-8 mb-2 list-decimal;
  }
  .rich-text-editor ul li {
    @apply ml-8 list-disc;
  }
  .rich-text-editor ul li p {
    @apply mb-2;
  }
  .rich-text-editor blockquote p {
    @apply my-8 ml-4 border-l-4 border-gray-300 pl-6 text-2xl italic text-gray-600;
  }
  .rich-text-editor iframe {
    @apply w-full;
  }
  .rich-text-editor a {
    @apply text-indigo-500;
  }

  /* Blog */
  .blog-post {
    @apply relative overflow-hidden pb-16;
  }

  .blog-post .blog-accent {
    @apply hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none];
  }

  .blog-post .blog-container {
    @apply relative px-6 lg:px-8;
  }

  .blog-post .blog-header {
    @apply mx-auto max-w-prose text-lg;
  }

  .blog-post p {
    @apply mt-8 text-lg leading-8 text-gray-500;
  }

  .blog-post li {
    @apply ml-4 text-lg;
  }

  .blog-post h1 {
    @apply mt-2 block text-center text-3xl font-bold leading-8 text-gray-900 sm:text-4xl;
  }

  .blog-post .category {
    @apply block text-center text-lg font-semibold text-indigo-600;
  }

  .blog-post .author-section {
    @apply mt-4 text-center text-sm;
  }

  .blog-post .author {
    @apply text-indigo-600 hover:text-indigo-700;
  }

  .blog-post .blog-body-container {
    @apply prose prose-lg prose-indigo mx-auto mt-6 text-gray-500;
  }

  .blog-post img {
    @apply w-full rounded-lg border border-gray-100 shadow-xl;
  }

  .blog-post figcaption {
    @apply -mt-6 mb-6 text-center italic;
  }

  /* Form Inputs */
  .text-input {
    @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:text-white sm:text-sm;
  }
  .text-input-large {
    @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
  }
  .onboarding {
    @apply border shadow-lg focus:border-none focus:outline-none;
  }
  .label {
    @apply mb-1 block text-sm font-semibold text-gray-900 dark:text-white;
  }
  .corner-hint {
    @apply text-sm text-gray-500 dark:text-white;
  }
  .helper-text {
    @apply mt-2 text-sm text-gray-400 dark:text-gray-400;
  }
  .invalid {
    @apply text-sm text-red-700;
  }
  .simple-dropdown-button {
    @apply relative h-9 w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500;
  }
  .simple-dropdown-container {
    @apply absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
  }

  /* Other */
  .button-primary {
    @apply mx-auto inline-flex w-60 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-semibold text-white shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm;
  }

  .text-link-button {
    @apply text-sm font-semibold text-indigo-600 hover:text-indigo-500;
  }
  .connection-button {
    @apply inline-flex h-8 items-center rounded-md border border-gray-300 bg-white px-3 py-4 text-sm font-semibold leading-4 text-gray-500 shadow transition-all hover:scale-[1.025];
  }

  .social-button {
    @apply mb-2;
  }

  .edit-button {
    @apply mb-0 inline-flex items-center rounded-md border border-indigo-300 bg-indigo-100 px-3 py-2 text-sm font-semibold leading-4 text-indigo-700 transition-all hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2;
  }

  .admin-button {
    @apply border border-orange-300 bg-orange-100 text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2;
  }

  .button-icon {
    @apply mr-2 -ml-0.5 h-4 w-4;
  }

  .social-icon {
    @apply h-4 w-4 text-gray-500;
  }

  .skeleton-text {
    @apply text-gray-300 dark:text-gray-400;
  }

  /* Tables */
  td {
    @apply px-4 py-3 !important;
  }
  th {
    @apply px-4 !important;
  }

  /* Global style mods */
  :root {
    @apply antialiased;
  }
  a {
    @apply text-inherit no-underline;
  }
}

/* Customizing Tooltip Design */
.tippy-box[data-theme~="base"] {
  background-color: theme("colors.tippy-bg");
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 1px 10px;
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tippy-box[data-theme~="base"] > .tippy-svg-arrow {
  fill: theme("colors.tippy-bg");
}
